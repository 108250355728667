import {throwError as observableThrowError} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";
const API_URL = environment.apiUrl + "/user";
const ADMIN_URL = environment.apiUrl + "/admin";
@Injectable()
export class UserService {
  constructor(private http: HttpClient) {}
  getPage() {
    return this.http.get(API_URL).pipe(map(res => res));
  }
  deactiveUser(id) {
    return this.http
      .post(API_URL + "/deactive/" + id, id)
      .pipe(map(res => res));
  }
  activeUser(id) {
    return this.http.post(API_URL + "/active/" + id, id).pipe(map(res => res));
  }
  getInfo(id) {
    return this.http.get(API_URL + "/info/" + id).pipe(map(res => res));
  }
  getcount() {
    return this.http.get(API_URL + "/userCount/").pipe(map(res => res));
  }
  getLatestuser() {
    return this.http.get(API_URL + "/latestUser/").pipe(map(res => res));
  }
  getiosuser() {
    return this.http.get(API_URL + "/iosUser/").pipe(map(res => res));
  }
  getandoriduser() {
    return this.http.get(API_URL + "/andoridUser/").pipe(map(res => res));
  }
  getwebuser() {
    return this.http.get(API_URL + "/webUser/").pipe(map(res => res));
  }
  getDeletedUser() {
    return this.http.get(API_URL + "/deletedusers/").pipe(map(res => res));
  }
  revokeDeletion(id) {
    return this.http.post(API_URL + "/revokedeletion/" + id, id).pipe(map(res => res));
  }
  sendNotification(Form) {
    const obj = {
      message: Form.message,
      user: Form.user
    };
    return this.http.post(API_URL + "/pushNotify", obj).pipe(map(res => res));
  }
  updatePageImage(formData: any, id) {
    let _url: string = ADMIN_URL + '/updateImage/' + id;
    return this.http.post(_url, formData).pipe(
      catchError(this._errorHandler),
      map(res => res));
  }
  private _errorHandler(error: Response) {
    console.error('Error Occured: ' + error);
    return observableThrowError(error || 'Some Error on Server Occured');

  }
  editPage(id) {
    const uri = API_URL + '/edit/' + id;
    return this
      .http
      .get(uri).pipe(
        map(res => {
          return res;
        }));
  }

  updatePage(formData: any, id) {
    const uri = ADMIN_URL + '/updateuser/' + id;
    console.log('consolelog_id',id);
    
    return this
      .http
      .post(uri, formData).pipe(
        catchError(this._errorHandler),
        map(res => res));
  }
}
