// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  domain: "",
  apiUrl: "https://cabi.co.tz:3000",
  socketUrl: "https://cabi.co.tz:8081",
  googleapiKey: "AIzaSyDbKyxJlNzaDz73Am4kqgPoCT-Ee5vfnuY",
  googleloginClientid: "719430875745-srn4eeh3hcqc1j8m3pct3hpt7h2nkvi4.apps.googleusercontent.com",
  facebookloginClientid: "",
  firebase: {
    apiKey: "AIzaSyDbKyxJlNzaDz73Am4kqgPoCT-Ee5vfnuY",
    authDomain: "cabi-taxi.firebaseapp.com",
    databaseURL: "https://cabi-taxi-default-rtdb.firebaseio.com",
    projectId: "cabi-taxi",
    storageBucket: "cabi-taxi.appspot.com",
    messagingSenderId: "719430875745",
    appId: "1:719430875745:web:aeff2d8f8d23b222380820",
    //measurementId: "ss"
  }
};


