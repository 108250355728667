
import { Injectable } from '@angular/core';
import {throwError as observableThrowError} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
const API_URL = environment.apiUrl+'/driverRoutes';

const ADMIN_URL = environment.apiUrl + "/admin";
@Injectable()
export class DriverService {
  constructor(private http: HttpClient) { }
  getPage() {
    return this
            .http
            .get(API_URL).pipe(
            map(res => {
              return res;
            }));
  }
  deactiveUser(id) { 
    const uri = API_URL+'/deactive/'+id;
    return  this.http.post(uri, id).pipe(
   map(res => res));
  }
  activeUser(id) {
    const uri = API_URL+'/active/'+id;
    return  this.http.post(uri, id).pipe(
   map(res => res));
  }
  getInfo(id){
    const uri = API_URL+'/info/' + id;
    return this
            .http
            .get(uri).pipe(
            map(res => {
              return res;
            }));
  }
  getcount(){
    const uri = API_URL+'/driverCount/';
    return this
            .http
            .get(uri).pipe(
            map(res => res));
  }
  getonlineDrivercount(){
    const uri = API_URL+'/onlineDriver/';
    return this
            .http
            .get(uri).pipe(
            map(res => res));
  }
  getactiveDrivercount(){
    const uri = API_URL+'/activeDriver/';
    return this
            .http
            .get(uri).pipe(
            map(res => res));
  }
  getDeletedDriver() {
    return this.http.get(API_URL + "/deleteddrivers/").pipe(map(res => res));
  }
  revokeDeletion(id) {
    return this.http.post(API_URL + "/revokedeletion/" + id, id).pipe(map(res => res));
  }
  updateDriverPage(formData: any, id) {
    const uri = API_URL + '/updatedriver/' + id;
    console.log('consolelog_id',id);
    return this
      .http
      .post(uri, formData).pipe(
        catchError(this._errorHandler),
        map(res => res));
  }
  updateDriverImage(formData: any, id) {
    let _url: string = API_URL + '/updateImage/' + id;
    return this.http.post(_url, formData).pipe(
      catchError(this._errorHandler),
      map(res => res));
  }
  private _errorHandler(error: Response) {
    console.error('Error Occured: ' + error);
    return observableThrowError(error || 'Some Error on Server Occured');

  }
}
